import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WorkspaceService } from "../../../services/workspace/workspace.service";
import { WorkspaceDto } from "../../../models/dto/workspace/workspaceDto";
import { ContributorDto } from "../../../models/dto/population/contributorDto";
import { PopulationService } from "../../../services/population/population.service";
import { PopulationDto } from "../../../models/dto/population/populationDto";
import {
  NewPopulationDialogComponent,
} from "../../global/population-select/new-population-dialog/new-population-dialog.component";
import { PopulationManagementService } from "../../../services/population/population-management.service";
import { PopulationStatisticsDto } from "../../../models/dto/population/populationStatisticsDto";
import { PopulationCreationApplicationDto } from "../../../models/dto/population/populationCreationApplicationDto";
import { environment } from "../../../environments/environment";
import { StyleManagerService } from "../../../services/style-manager/style-manager.service";
import { PopulationOverviewDto } from "../../../models/dto/population/populationOverviewDto";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ILoggingService } from "../../../services/logging/logging.service.interface";
import { MatDialog } from "@angular/material/dialog";
import { ErrorHandlerService } from "../../../services/error/error-handler.service";
import { HttpErrorResponse } from "@angular/common/http";
import { tap } from 'rxjs/operators';
import { ItemResponseDto } from '../../../models/dto/response/itemResponseDto';
import { ResponseDto } from '../../../models/dto/response/responseDto';
import { OrganizationJoinRequestComponent } from '../../user/organization/organization-profile/organization-join-request/organization-join-request.component';
import { UuidService } from '../../../services/utilities/uuid.service';
import { formatNumber } from '@angular/common';


@Component({
  selector: 'app-populationmain',
  templateUrl: './populationmain.component.html',
  styleUrls: ['./populationmain.component.scss']
})
export class PopulationMainComponent implements OnInit, OnChanges {

  public dark: boolean = true;
  public updating = false;

  workspace: WorkspaceDto | undefined;
  population: PopulationDto | undefined;
  statistics: PopulationStatisticsDto | undefined;


  populationOverviews: Observable<Array<PopulationOverviewDto>> | undefined;
  contributors: Array<ContributorDto> | undefined;

  contributorGroups: Map<string, Array<ContributorDto>> | undefined;
  contributorsLoaded: boolean = false;

  contributorGroupNames: Array<string> | undefined;

  headerImgSrc = environment.aboutHeaderImg;
 // idService: UuidService;


  overview: PopulationOverviewDto | undefined;
    
 
 

  constructor(
    private workspaceService: WorkspaceService,
    private populationService: PopulationService,
    private themeService: StyleManagerService,
    private style: StyleManagerService,
    private log: ILoggingService,
    private router: Router,
    private dialog: MatDialog,
    private errorHandler: ErrorHandlerService,
    private populationManagementService: PopulationManagementService,
    private idService: UuidService,
  ) {
     
  }

  handlePopulationClick(event: Event, pop: any): void {
    event.preventDefault();  
    if (pop.isInPopulation) {
      this.updatePopulation(pop.population);
    } else if (!pop.isInPopulation && !pop.requireJoinRequest) {
      this.joinPopulation(pop.population ?? null);
    } else if (pop.requireJoinRequest) {
      this.joinPopulation(pop.population ?? null);
    }
  }

  getLinkText(pop: any): string {
    if (pop.isInPopulation) {
      return 'SELECT POPULATION';
    } else if (!pop.isInPopulation && !pop.requireJoinRequest) {
      return 'JOIN POPULATION';
    } else {
      return 'REQUEST ACCESS';
    }
  }

  joinPopulation(population: PopulationDto): void  {
    this.populationService.getPopulationJoinRequestRequired('' + population.id).subscribe({
      next: (value: ItemResponseDto<boolean>) => {

        if (!value.successful) {
          this.log.error(`Could not check if population requires join permission. ${value.errorMessages.join(', ')}`);
          return;
        }
        if (value.item) {

          var vid = this.idService.generate();
          const ref = this.dialog.open(OrganizationJoinRequestComponent, {
            data: {
              id: vid,
              user: this.workspace?.settings?.user,
              userId: this.workspace?.settings?.user?.id,
              item: population,
              itemId: population.id,
              itemName: population.displayName
            }
          })
          ref.afterClosed().subscribe(res => {
            if (res) {
              this.populationService.submitJoinRequest(res).subscribe({
                next: (value: ResponseDto) => {
                  if (value.successful) {
                    this.log.info(`Your request has been successfully submitted. Please wait until the administrators have review it.`)
                  } else {
                    this.log.error(`Your response could not be submitted: ${value.errorMessages.join(', ')}`)
                  }
                }, error: (value: HttpErrorResponse) => {
                  this.log.error(`Your response could not be submitted: ${value.message}`)
                }
              })
             // this.dialogRef.close()
            }

          })
        } else {
          this.populationService.joinPopulation(population).subscribe({
            next: (value: PopulationDto) => {
              this.log.info(`Joined ${value.speciesName} -- ${value.displayName}`, true);
              //this.dialogRef.close(value);
              this.populationService.select(value);

              this.updatePopulation(population);
            },
            error: (error: HttpErrorResponse) => {
              this.log.error(`Could not join ${population.displayName}: ${error.error}`, true);
            }
          })
        }
      }
    })
  }

  updatePopulation(population: PopulationDto): void {
    //this.selectedPopulation = population;
    //this.populationChanged.emit(population);

    this.workspace!.settings!.population = population;

    this.workspaceService.switchPopulation(population)
      .subscribe({
        next: (value: WorkspaceDto) => {
          this.workspaceService.setWorkspace(value);
          this.log.info(`Switched to ${population.displayName}`);
          this.router.navigate(['/about']);
        }, error: (value: HttpErrorResponse) => {
          this.log.error(`Could not switch to ${population.displayName}:: ${value.message}`)
        }
      }); 
  }
  
  newPopulation(): void {

      const ref = this.dialog.open(NewPopulationDialogComponent, {
        closeOnNavigation: true,
        data: new PopulationCreationApplicationDto()
      });
      
      ref.afterClosed().subscribe(res => {
        if (res && res.location) {
          res.location.name = `${res.displayName} center`;
          this.populationManagementService.createApplication(res).subscribe({
            next: () => {
              this.log.info(`Application for ${res.displayName} successfully created`);
            },
            error: (err: HttpErrorResponse) => {
              this.errorHandler.handleRequestError("Creating Population Request", err);
            }
          })
        }

      })
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  ngOnInit(): void {
    this.updating = true;
    this.style.isDarkObs.subscribe(res => {
      this.dark = res;
    })

    this.workspaceService.workspace.subscribe(workspceRes => {
      if (workspceRes && workspceRes.settings) {
        this.workspace = workspceRes;

        this.populationOverviews = this.populationService.getPopulationOverviews().pipe(
          tap(data => {
            console.log('Population Overview Data:', data);
            // You can perform other side effects here if needed
            this.updating = false;
          })
          
        );
        
       
      }

    })
    

  }

  formatNumberStr(number?: Number): string {
    return formatNumber(Number(number), 'en-US', '1.0-0');
  }

   
}
